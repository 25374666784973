import styled from 'styled-components'


const inlineCode = styled.code`
    font-family: 'Source Code Pro', monospace;
    background-color: var(--offer-white);
    color: var(--off-dark);
    /* border-radius: 3px; */
    padding: 1px 6px 1px 6px;
    font-weight: bold;
`

export default inlineCode
import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const UnorderedList = styled.ul`
  margin-top: 3rem;
  padding: 0;
  list-style-type: decimal-leading-zero;
  list-style-position: inside;
  font-size: 14px;
  line-height: 1.5;
`

const HeaderLink = styled(props => <Link {...props} />)`
  font-family: 'Source Code Pro', monospace;
  font-weight: 400;
  text-decoration-line: none;
  &:link {
    color: var(--off-dark);
  }
  &:visited {
    color: var(--off-dark);
  }
`

const ListItem = styled.li`
  &:hover {
    background-color: var(--off-dark);
    color: var(--alert-yellow);
    font-weight: bold;
  }
`

const TableOfContents = ({headings}) => {
  if (typeof headings.items === 'undefined') {
    return null
  } else {
    return (
      <UnorderedList>
        {headings.items.map(
          item => (
            <HeaderLink to={item.url} key={item.title}>
              <ListItem>
                {item.title}
              </ListItem>
            </HeaderLink>
          )
        )}
      </UnorderedList>
    )
  }
}

export default TableOfContents;
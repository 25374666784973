import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const ImageContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: ${props => (props.width ? props.width : "auto")};
`

const Image = props => {
  return (
    <ImageContainer width={props.width}>
      <Img fluid={props.src.fluid} />
    </ImageContainer>
  )
}

export default Image

import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Img from "gatsby-image"
import Image from "../components/image"
import CodeBlock from "../components/mdx/codeblock"
import Paragraph from "../components/mdx/paragraph"
import TableOfContents from "../components/mdx/toc"
import Headings from "../components/mdx/headings"
import inlineCode from "../components/mdx/inlinecode"
import Anchor from "../components/mdx/anchor"
import BlockQuote from "../components/mdx/blockquote"
import Layout from "../components/global-layout"
import SEO from "../components/seo"
import StyledLink from "../components/link"

const shortcodes = { 
  StyledLink,
  Img,
  Image,
  a: Anchor,
  p: Paragraph,
  pre: CodeBlock,
  h2: Headings.h2,
  inlineCode: inlineCode,
  blockquote: BlockQuote
}


// TODO: try out gatsby-remark-copy-files later.
export default function PageTemplate({ data: { mdx } }) {
  const IMAGE_KEY = "image"
  const embeddedImagesByKey =
    mdx.frontmatter.embeddedImages &&
    mdx.frontmatter.embeddedImages.reduce((images, image, index) => {
      images[`${IMAGE_KEY}${index + 1}`] = images[
        `${IMAGE_KEY}${index + 1}`
      ] || {
        ...image.childImageSharp,
      }
      return images
    }, {})
  const seo_title = mdx.frontmatter.title.toLowerCase()
  return (
    <Layout>
      <SEO title={seo_title} />
      <Headings.h1>{mdx.frontmatter.title}</Headings.h1>
      <TableOfContents headings={mdx.tableOfContents}/>
      <MDXProvider components={shortcodes}>
        <MDXRenderer 
          embeddedImages={embeddedImagesByKey}
        >
          {mdx.body}
        </MDXRenderer>
      </MDXProvider>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      tableOfContents
      frontmatter {
        title
        embeddedImages {
          childImageSharp {
            original {
              width
              height
              src
            }
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

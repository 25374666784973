import styled from "styled-components"

const Headings = {
  h1: styled.h1`

  `,
  h2: styled.h2`

  `,
  h3: styled.h3`
  
  `,
  h4: styled.h4`
  
  `,
  h5: styled.h5`
  
  `,
  h6: styled.h6`
  
  `
}

export default Headings;
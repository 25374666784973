import styled from 'styled-components'


const CodeBlock = styled.pre`
    @import url(a-url-to-google-fonts);
    font-family: 'Source Code Pro', monospace;
    background-color: var(--off-dark);
    color: var(--off-white);
    padding: 20px;
    border-radius: 5px;
    display: block;
    overflow-x: auto;
    margin-right: 25px;
    margin-left: 25px;
`

export default CodeBlock
import styled from 'styled-components'

const Paragraph = styled.p`
  margin: 0;
  margin-bottom: 1rem;
  color: var(--off-dark);
  font-size: 18px;
  line-height: 1.6;
`

export default Paragraph
// import React from 'react'
import styled from 'styled-components'

const BlockQuote = styled.blockquote`
  border-left: 5px solid var(--off-dark);
  padding-left: 1rem;
  margin-left: 1rem;
  & > p {
    display: inline;
    font-family: 'Source Code Pro', sans-serif;
  }
`

export default BlockQuote